.post-image {
    margin-bottom: 30px;
    width: 100%;
}

.main--news-summary {
    #work-angle {
        left: 80px !important;
    }
}

// Layout and global styles

// Import external partials
@import "external/minimal-grid";
//@import "external/color-palette";
//@import "external/swipebox";
@import "external/mmenu";
@import "external/animate";

// Import base partials (resets)
@import "base/colours";
@import "base/reset";
@import "base/mixins";
@import "base/fonts";

// Import global styles
@import "global/typography";
@import "global/background";
@import "global/header";
@import "global/footer";
@import "global/nav-primary";
@import "global/content";
@import "global/breadcrumbs";
@import "forms/gravity";

// Import page styles
@import "page/home";
@import "page/work";
@import "page/post";
@import "page/contact";
@import "page/clients";

#main-footer {
    position: absolute;
    float: left;
    z-index: 92;
    width: 100%;
    padding-left: 5px;
    bottom: 1px;

    @include breakpoint(to-medium) {
      position: static;
      width: 100%;
      margin: 0 auto;
    }

    #social-nav {
        float: left;
        margin: 20px 0 10px;

        @include breakpoint(to-medium) {
            width: 100%;
            text-align: center;
            margin-bottom: 20px;
            float: none;
        }

        li {
            position: relative;
            float: left;
            clear: both;
            margin: 0 8px 7px;

            @include breakpoint(to-medium) {
                float: none;
                clear: none;
                display: inline-block;
            }

            .social-icon {
                display: block;
                width: 32px;
                height: 32px;
                text-indent: -999em;
            }
            $socialIconSize: 90%;
            .facebook {
                background: url("../images/social-icon-facebook.svg") no-repeat;
                background-position: top;
                background-size: $socialIconSize;

            @include breakpoint(to-medium) {
              background-position: center;
            }
          }

            .pinterest {
                background: url("../images/social-icon-pininterest.svg") no-repeat;
                background-position: center;
                background-size: $socialIconSize;

                @include  breakpoint(to-medium) {
                  background-position: center;
                }
            }

            .linkedin {
                background: url("../images/social-icon-linkedin.svg") no-repeat;
                background-position: bottom;
                background-size: $socialIconSize;
                @include breakpoint(to-medium) {
                  background-position: center;
                }
            }

            .instagram {
                background: url("../images/social-icon-instagram.svg") no-repeat;
                background-size: $socialIconSize;
                margin-top: 4px;



                @include breakpoint(to-medium) {
                    display: block;
                    margin-top: 4px;
                    background-position: center;
                }
            }
        }
    }

    #footer-text {
        font-family: $font-family--small;
        font-size: 10px;
        color: $black;
        position: absolute;
        bottom: 10px;
        left: 60px;
        width: auto;

        @include breakpoint(to-medium) {
            width: 100%;
            position: static;
            text-align: center;
            left: 0;
        }
    }
    #menu-footer-menu {

      @include breakpoint(to-medium) {
        text-align: center;
        padding-bottom: 10px;
      }
      @include breakpoint(from-medium) {
        position: relative;
        top: -10px;
      }

      li {
        display: inline-block;
        padding: 0 10px;
        border-right: 1px solid $light-gray;
        line-height: 9px;

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          border-right: none;
        }

        a {
          font-size: 9px;
          @extend %transition;

          &:hover {
            color: $black;
          }
        }
      }
    }
}

.page-template-work-page #main-footer,
.category #main-footer {
    display: none;
}

.page-template-front-page #main-footer,
.news-summary #main-footer {

  @include breakpoint(from-medium) {
    position: absolute !important;
    left: 5px !important;
    bottom: 35px !important;
    z-index: 92 !important;
    width: 90px !important;

    #footer-text {
        display: none !important;
    }
}
}

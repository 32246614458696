.work-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    height: 100%;

    @include breakpoint(to-medium) {
        display: block;
        width: 100%;
        height: 100%!important;
    }

    .work-grid {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        height: 100%;
        overflow: hidden;
        position: relative;

        @include breakpoint(to-medium) {
            display: none;
        }

        li {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-flex: 0;
                -ms-flex: 0 0 auto;
                    flex: 0 0 auto;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            height: 100%;
            position: relative;
            width: 330px;
            margin-left: 0 !important;

            article {
                height: 100%;
                width: 100%;
                display: none;
                position: relative;

                .work-image {
                    position: absolute;
                    z-index: 30;
                    height: 100%;

                    img {
                        @extend %transition;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }
    }

    .work-subnav {

        padding: 80px 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        z-index: 80;
        background-color: $white;
        min-width: 200px;

        @include apply-to-IE {
            margin-left: 120px;
        }

        @include breakpoint(to-medium) {
            display: block;
            width: 100%;
            float: left;
            clear: both;
            padding: 20px;
        }

        ul {
            display: block;
            width: 160px;
            position: relative;
            z-index: 81;
            margin-left: 0 !important;

            li {
                padding: 9px 0;
                list-style: none !important;
                list-style-position: outside !important;
                margin-left: 0!important;

                span, a {
                    font-family: $font-family--body;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: $black;
                }

                span {
                    font-family: $font-family--heading;
                }

                a {
                    font-weight: normal;
                    text-decoration: none;
                }
            }
        }
    }

    .work-spacer {
        width: 80px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        z-index: 80;
        background-color: $white;
        min-width: 80px;

        @include apply-to-IE {
            margin-left: 120px;
        }

        @include breakpoint(to-medium) {
            display: block;
            width: 100%;
            float: left;
            clear: both;
        }
    }

    .work-content {
        position: absolute;
        z-index: 50;
        bottom: -20px;
        left: 0;
        width: 100%;
        height: 270px;
        background: url("../images/work-landing-content-bg.png") 0 0 no-repeat;

        @include breakpoint(to-small) {
          bottom: 0;
        }

        &:hover {

          .button {
            background-color: $black;
            color: $white;
          }
        }

        a {
          text-decoration: none;
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }

        h2 {
            color: $white;
            font-family: $font-family--heading;
            text-transform: uppercase;
            font-size: 25px;
            width: 330px;
            text-align: center;
            margin-top: 75px;
            padding-left: 16px;
            padding-right: 16px;
            line-height: 125%;
            margin-bottom: 4px;
        }

        p {
            color: $white;
            font-family: $font-family--light;
            text-align: center;
            padding: 5px 20px;
            line-height: 20px;
            margin: 0;
            margin-bottom: 10px;

            @include breakpoint(from-medium) {
              padding: 5px 60px;
              height: 57px;
              margin-bottom: 0;
            }
        }

        .button {
            display: block;
            width: 50px;
            height: 22px;
            line-height: 24px;
            background-color: $white;
            color: $black;
            font-size: 11px;
            font-family: $font-family--small;
            text-transform: uppercase;
            text-align: center;
            text-decoration: none;
            margin: 0 auto;
            position: relative;
            z-index: 66;
            -webkit-transition: background-color 0.2s linear;
            transition: background-color 0.2s linear;
            margin-top: -5px;

            @include breakpoint(from-medium) {
              position: absolute;
              bottom: 40px;
              left: 50%;
              margin-left: -25px;
              margin-top: 0;
            }

            &:before,
            &:after {
                content: "";
                width: 20px;
                border-bottom: 1px dotted $white;
                position: absolute;
                top: 50%;
            }

            &:before {
                left: -25px;
            }

            &:after {
                right: -25px;
            }

            &:hover {
                background-color: $black;
                color: $white;
            }
        }
    }

    #work-angle {
        width: 80px;
        height: 100%;
        position: absolute;
        left: 300px;
        top: 0;
        z-index: 80;

        @include breakpoint(to-medium) {
            display: none;
        }
    }

    #scroll-right {
        width: 20px;
        height: 20px;
        background: url("../images/arrow-right.svg") 0 0 no-repeat;
        position: fixed;
        top: 50%;
        right: 14px;
        margin-top: -10px;
        display: block;
        z-index: 8888;

        &.animated {
            -webkit-animation-duration: 2.5s;
            animation-duration: 2.5s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
            animation-iteration-count:infinite;
            -webkit-animation-iteration-count:infinite;
        }
        @-webkit-keyframes bounce {
            20% {-webkit-transform: translateX(-6px);}
            80% {-webkit-transform: translateX(6px);}
        }
        @keyframes bounce {
            20% {-webkit-transform: translateX(-6px);transform: translateX(-6px);}
            80% {-webkit-transform: translateX(6px);transform: translateX(6px);}
        }
        &.bounce {
            -webkit-animation-name: bounce;
            animation-name: bounce;
        }
        @include breakpoint(to-medium) {
            display: none;
        }
    }

    .work-grid-mobile {
        @include breakpoint(to-medium) {
            display: block;
            width: 100%;
            margin-left: 0!important;

        }
        @include breakpoint(from-medium) {
            display: none;
        }

        li {
            display: inline-block;
            width: 100%;
            //padding-right: 20px;
            margin-bottom: 10px;
            margin-left: 0 !important;

            article {
                position: relative;

                .work-image {
                    position: relative;
                    z-index: 30;
                    width: 100%;

                    img {
                        width: 100%;
                    }
                }

                .work-content {
                    background-image: none;
                    background-color: rgba(0,0,0, 0.5);
                    height: 150px;

                    h2 {
                        width: 100%;
                        margin-top: 24px;
                    }
                }
            }
        }
    }
}

.main--work-detail {
    h2 {
        margin-bottom: 30px;
        font-size: 25px;
        font-weight: 100;
    }

    .work-image,
    .work-gallery {
        img {
            width: 100%;
            margin: 15px 0;
        }
    }
    .work-gallery {
        img {
            @include breakpoint(from-medium) {
                width: 48.5%;
                float: left;
                margin-right: 1.25%;
            }
        }
        li:nth-child(3n) {
            img {
                @include breakpoint(from-medium) {
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                }
            }
        }
        li:nth-child(3n+2) {
            img {
                @include breakpoint(from-medium) {
                    margin-right: 0;
                    margin-left: 1.25%;
                }
            }
        }
    }

    .work-images {
        margin: 30px 0;
        float: left;
        clear: both;

        .images-row {
            float: left;
            clear: both;
            width: 100%;

            img {
                float: left;
                clear: none;
            }

            &.single img {
                width: 100%;
            }

            &.split_even img {
                width: 50%;

                @include breakpoint(to-medium) {
                  width: 100%;
                }
            }

            &.triple img {
                width: 33.33%;

                @include breakpoint(to-medium) {
                  width: 100%;
                }
            }

            &.split_larger_left {
                img:first-child {
                    width: 70%;
                }
                img:last-child {
                    width: 30%;
                }
            }

            &.split_larger_right {
                img:first-child {
                    width: 30%;
                }
                img:last-child {
                    width: 70%;
                }
            }
        }
    }
}

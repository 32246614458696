// Defautlts
// @font-face {
// 	font-family: 'FontAwesome';
// 	src: url('../fonts/fontawesome-webfont.eot?v=4.1.0');
// 	src: url('../fonts/fontawesome-webfont.eot?#iefix&v=4.1.0') format('embedded-opentype'), url('../fonts/fontawesome-webfont.woff?v=4.1.0') format('woff'), url('../fonts/fontawesome-webfont.ttf?v=4.1.0') format('truetype'), url('../fonts/fontawesome-webfont.svg?v=4.1.0#fontawesomeregular') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }
// Gotham Font
@font-face {
  font-family: 'Gotham Book';
  src: url('../fonts/gotham-book-webfont.eot');
  src: url('../fonts/gotham-book-webfont.eot') format('embedded-opentype'),
       url('../fonts/gotham-book-webfont.woff') format('woff'),
       url('../fonts/gotham-book-webfont.ttf') format('truetype'),
       url('../fonts/gotham-book-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Bold';
  src: url('../fonts/gotham-bold-webfont.eot');
  src: url('../fonts/gotham-bold-webfont.eot') format('embedded-opentype'),
       url('../fonts/gotham-bold-webfont.woff') format('woff'),
       url('../fonts/gotham-bold-webfont.ttf') format('truetype'),
       url('../fonts/gotham-bold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Light';
  src: url('../fonts/gotham-light-webfont.eot');
  src: url('../fonts/gotham-light-webfont.eot') format('embedded-opentype'),
       url('../fonts/gotham-light-webfont.woff') format('woff'),
       url('../fonts/gotham-light-webfont.ttf') format('truetype'),
       url('../fonts/gotham-light-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Gotham Light Italic';
  src: url('../fonts/gotham-light-italic-webfont.eot');
  src: url('../fonts/gotham-light-italic-webfont.eot') format('embedded-opentype'),
       url('../fonts/gotham-light-italic-webfont.woff') format('woff'),
       url('../fonts/gotham-light-italic-webfont.ttf') format('truetype'),
       url('../fonts/gotham-light-italic-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

// From CDN
@font-face {
    font-family: 'FontAwesome';
    src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?v=4.3.0');
    src: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.eot?#iefix&v=4.3.0') format('embedded-opentype'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.woff2?v=4.3.0') format('woff2'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.woff?v=4.3.0') format('woff'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.ttf?v=4.3.0') format('truetype'), url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.3.0/fonts/fontawesome-webfont.svg?v=4.3.0#fontawesomeregular') format('svg');
    font-weight: normal;
    font-style: normal
}

// helper class for font awesome icons
.fa {
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

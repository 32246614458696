#main {
    padding-left: 100px;
    display: block;

    @include breakpoint(to-medium) {
        padding: 0 10px;
        margin-top: 40px;
    }

    &.main--home-page {
      padding: 0;

      @include breakpoint(to-medium) {
        .work-grid-mobile {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    ul,ol {
      margin-bottom: 12px;
      margin-left: 20px;

      li {
        list-style: square;
        list-style-position: outside;
        padding-bottom: 2px;
        margin-left: 8px;
      }
    }
    ol li {
      list-style: decimal;
    }
}

.container {

    @include breakpoint(to-medium) {
        padding: 65px 30px 20px 10px;
    }

    @include breakpoint(from-medium) {
        padding: 85px 20px 60px 0;
        max-width: 960px;
        margin: 0 auto;
        overflow: auto;
    }

}

// Global typography styles
$font-family--body: 'Gotham Light', sans-serif;
$font-family--heading: 'Gotham Bold', sans-serif;
$font-family--nav: 'Gotham Book', sans-serif;
$font-family--light: 'Gotham Light', sans-serif;
$font-family--small: 'Gotham Book', sans-serif;
$font-family--italic: 'Gotham Light Italic', sans-serif;

h1 {
    font-family: $font-family--heading;
    font-weight: normal;
    font-size: 19px;
    color: $black;
    text-transform: uppercase;
    margin-bottom: 25px;

    span {
        font-family: $font-family--body;
        position: relative;
        top: 1px;
    }
}

h2 {
    font-family: $font-family--body;
    margin-top: 8px;
    margin-bottom: 12px;
    font-size: 27px;
    color: $black;
}
h3 {
  font-family: $font-family--body;
  font-weight: normal;
  color: $black;
  font-size: 20px;
  margin-bottom: 12px;
}
h4, h5 {
  font-family: $font-family--heading;
  font-weight: normal;
  color: $black;
  font-size: 13px;
  margin-bottom: 12px;
  text-transform: uppercase;
}

p {
    margin-bottom: 12px;

    strong {
        font-family: $font-family--heading;
    }
}

body {
    font-family: $font-family--body;
    font-size: 14px;
    color: $black;
    font-weight: normal;
    line-height: 21px;
}

a {
    color: $dark-gray;
}

i, em {
  font-family: $font-family--italic;
  font-weight: normal;
}

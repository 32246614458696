.main--client-page {
  .container {
    overflow: auto;
    @include breakpoint(to-medium) {
      padding: 80px 2% 10px;
    }
    .client-list {
        overflow: visible;
        width: 100%;
        margin-top: 145px;
        @include breakpoint(to-medium) {
        text-align: center;
      }
      ul {
        overflow: visible;
      }
        li {
            display: inline-block;
            width: 150px;
            height: 200px;
            margin: 0 20px 0 0;

            @include breakpoint(to-small) {
                width: 43%;
                float: left;
                padding: 1%;
            }
            @include breakpoint(to-medium) {
              margin: 0 3% 0 3%;
            }

            a {
                display: block;
                position: relative;
                text-align: center;
                filter: gray;
                filter: grayscale(1);
                -webkit-filter: grayscale(1);
                transition: filter 0.2s linear;
                padding: 5%;
                @extend %transition;

                img {
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    max-width: none;
                    width: 85%;
                    @include breakpoint(to-small) {
                    width: 100%;
                  }
                }

                &:hover, &:active, &:focus {
                    filter: none;
                    -webkit-filter: grayscale(0);

                }
            }
        }
    }
  }
}

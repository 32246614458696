.gform_body {

    width: 270px !important;

    @include breakpoint(to-medium) {
        width: 250px !important;
    }

    li {
        width: 100% !important;
    }

    label {
        display: none !important;
    }

    input, textarea {
        display: block;
        float: left;
        clear: both;
        width: 100% !important;
        font-family: $font-family--small !important;
        font-size: 11px !important;
        border: 1px solid $light-gray;
    }

    input {
        height: 30px;
        margin: 0 0 15px 0;
        line-height: 30px !important;
        padding: 0 10px !important;
    }

    textarea {
        padding: 10px !important;
    }

}

.gform_button {
    background-color: $black !important;
    border: 2px solid $black !important;
    color: $white;
    font-size: 11px !important;
    font-family: $font-family--nav;
    text-transform: uppercase;
    height: 30px;
    line-height: 28px;
    padding: 0 10px;
    cursor: pointer !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
    -webkit-appearance: none;

    &:hover {
        border: 2px solid $dark-gray !important;
        background-color: $dark-gray !important;
    }

    &:active {
        background-color: $white !important;
        border: 2px solid $black !important;
        color: $black !important;
    }
}

.validation_error,
.gfield_description {
    color: $red !important;
    font-size: 12px !important;
    font-weight: normal !important;
    line-height: 20px !important;
    border: none !important;
    margin-bottom: 10px !important;
}

.gform_wrapper li.gfield.gfield_error,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    border: none !important;
    background: none !important;

    input,
    textarea {
        border: 1px solid $red !important;
        margin-bottom: 0 !important;
    }
}

.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required fieldset legend.gfield_label,
.gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin-left: 0 !important;
}

.gfield_error .ginput_container {
    max-width: 100% !important;
}
// Various Mixins

// Transition opacity
@mixin transition-opacity($transition) {
	transition: opacity #{$transition}s ease-out;
	-moz-transition: opacity #{$transition}s ease-out;
	-webkit-transition: opacity #{$transition}s ease-out;
	-o-transition: opacity #{$transition}s ease-out;
}

// Border Radius
@mixin border-radius($radius) {
border-radius: $radius;
	  	behavior: url(/?pie=true); /* PIE CSS3 */
}
// Box Shadow
@mixin box-shadow() {
	-webkit-box-shadow: 0 0 10px 1px rgba(0,0,0,0.5);
	box-shadow: 0 0 10px 1px rgba(0,0,0,0.5);
	behavior: url(/?pie=true); /* PIE CSS3 */
}

// Text Shadow
@mixin text-shadow() {
	text-shadow: 1px 1px 10px #000;
  	behavior: url(/?pie=true); /* PIE CSS3 */
}
// Text Outline
%textshadow {
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.4);
}
%text-shadow-white {
	text-shadow: 1px 1px 0 rgba(255,255,255,0.5);
}

// Font Awesome
// @include font-awesome("\f18e");
@mixin font-awesome($icon_code) {
	display: inline-block;
	font-family: FontAwesome;
	font-style: normal;
	font-weight: normal;
	line-height:1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	content:"#{$icon_code}";
}

// Linear radient
@mixin linear-gradient($fromColor, $toColor) {
  background-color: $toColor; /* Fallback Color */ /* Saf4+, Chrome */ /* Chrome 10+, Saf5.1+, iOS 5+ */ /* FF3.6 */ /* IE10 */ /* Opera 11.10+ */
  background-image:         -webkit-gradient(linear, left top, left bottom, from($fromColor), to($toColor));
  background-image:         linear-gradient(top, $fromColor, $toColor);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#{$fromColor}', EndColorStr='#{$toColor}');
}
// Transitions
%transition {
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
// responsive video embeds
%embed-container {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}

%embed-container iframe,
%embed-container object,
%embed-container embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

// black & white images
%black-white {
	-moz-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -o-filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
    -webkit-filter: grayscale(100%);
    filter: gray;
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
}

// Icons
%icon {
	color: #fff;
	height: 80px;
	width: 80px;
	line-height: 80px;
	border-radius: 45px;
	margin: 16px auto 26px;
}

// Browser Specific mixins

// IE10 Fix
@mixin apply-to-IE {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
	@media screen and (min-width:0\0) {
		@content;
	}
}

//  Device CSS3 Animation Fix
@media screen and (min-device-width:0) and (max-device-width:1024px) {
	// Turn off animations
	.animated {
		-webkit-animation-fill-mode: none;
		animation-fill-mode: none;
		-webkit-animation-duration: 0;
		animation-duration: 0;
	}
}
.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}
.preload .animated {
    visibility: hidden;
}

#main-header {
    position: fixed;
    left: 0;
    top: 0;
    width: 120px;
    height: 100%;
    overflow: hidden;
    z-index: 91;

    @include breakpoint(to-medium) {
        display: none;
    }

    #navbg {
        width: 500px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 90;
    }

    #nav-button {
        display: block;
        position: absolute;
        z-index: 91;
        width: 22px;
        height: 22px;
        top: 20px;
        left: 20px;
        outline: none;

        .open {
            display: block;
        }

        .close {
            display: none;
        }
    }

    #main-nav {
        position: absolute;
        z-index: 91;
        top: 100px;
        left: 75px;
        display: none;

        ul {
            li {
                text-transform: uppercase;

                a {
                    color: $white;
                    font-size: 14px;
                    font-family: $font-family--nav;
                    text-decoration: none;
                    display: block;
                    line-height: 36px;
                }
                &.current-menu-item a {
                    font-family: $font-family--heading;
                }
            }
        }
    }
}

.open-menu {
    display: none;
    position: relative;
    top: 20px;
    left: 20px;
    z-index: 999;
    width: 25px;

    #mobile-icon-open {
        display: block;
    }

    #mobile-icon-close {
        display: none;
    }

    @include breakpoint(to-medium) {
        display: block;
    }

}

// trying to fix flicker on mobile menu
#handheld-menu {
    &:not(.mm-menu) {
       display: none;
    }

    @include apply-to-IE {
        opacity: 0;

        &.mm-opened {
            opacity: 1;
        }
    }
}

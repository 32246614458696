#reveal {
    display: block;
    position: absolute;
    z-index: 201;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $black;
    background-image: url("../images/reveal-logo.svg");
    background-size: 2500px;
    background-repeat: no-repeat;
    background-position: left;
    overflow: hidden;
}

#home-content {
    margin: 100px auto;
    width: 560px;

    @include breakpoint(to-medium) {
        width: 260px;
        margin: 50px auto 90px auto;
    }

    #home-logo {
        width: 330px;
        display: block;
        margin: 0 auto 30px auto;

        @include breakpoint(to-medium) {
            width: 160px;
        }
    }

    #home-statement {
        text-align: center;
        p {
            position: relative;
            z-index: 202;
            color: $white;
            font-size: 17px;
            line-height: 24px;
            transition: color 0.15s linear;

            &.revealed {
                color: $black;
            }
        }
    }

}

.page-template-front-page {
    #main {
        padding-left: 0;
    }

    #work-angle {
        left: 70px !important;
    }

    .container {
        position: absolute;
        width: 100%;
        max-width: 100%;
    }

    .work-container {
        margin-left: 100%;
    }

    .work-content {
        display: none;
    }
}
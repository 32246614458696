#breadcrumbs{
    list-style:none;
    margin: 0 0 16px !important;
    overflow:hidden;
}

#breadcrumbs li{
    display: inline-block;
    vertical-align: middle;
    margin-right: 0;
    font-size: 11px;
    list-style: none;
    float: left;
    color: light-gray;
    padding-left: 2px;
    padding-right: 2px;
    margin-left: 0 !important;
    text-transform: lowercase;

    &:first-child {
      padding-left: 0;
    }
    a {
      text-decoration: none;
    }
}

#breadcrumbs .separator{
    font-size:18px;
    font-weight:100;
    color: $light-gray;
}
